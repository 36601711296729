import http from '../plugins/utils/http'
let api = '/api'
const center = {
    // 个人中心残疾人信息
    getMyDisAbledInfo(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/getMyDisAbledInfo`, params)
    },
	//查看简历完善度
	getMyDisAbledIfFull(params) {
	    return http.get(`${api}/app-jycy-disabled/disbled/ifFull`, params)
	},
    // 查看
    getDisabledCountInfo(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/getDisabledCountInfo`, params)
    },
    // 为你推荐
    getWorkList(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/getMyDisAbledInfo`, params)
    },
    // 推荐简历
    queryAboutHopeJob(params) {
        return http.get(`${api}/app-jycy-job/disableds/queryAboutHopeJob`, params)
    },
    // 简历预览
    disableResume(params) {
        return http.get(`${api}/app-jycy-disabled/DisableResume`, params)
    },
    //刷新简历
    refreshResume(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/refreshResume`, params)
    },
    // ----------------我的简历
    // 残疾人信息
    saveDisabled(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/saveDisabled`, params)
    },
    // 求职意向编辑
    resumeExpect(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/updateMyResumeExpect`, params)
    },
    // 教育经历编辑
    resumeEdu(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/addOrUpdateMyResumeEdu`, params)
    },
    // 语言能力编辑
    resumeLanguage(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/addOrUpdateMyResumeLanguage`, params)
    },
    // 项目经历编辑
    resumeProject(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/addOrUpdateMyResumeProject`, params)
    },
    // 专业技能编辑
    resumeSkill(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/addOrUpdateMyResumeSkill`, params)
    },
    // 培训经历编辑
    resumeTrain(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/addOrUpdateMyResumeTrain`, params)
    },
    // 工作经历编辑
    resumeWork(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/addOrUpdateMyResumeWork`, params)
    },
    // 证书经历编辑
    resumeCert(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/addOrUpdateMyResumeCert`, params)
    },
    // 自我评价编辑
    updateMyresumeDescription(params) {
        return http.post(`${api}/app-jycy-disabled/disbled/updateMyresumeDescription`, params)
    },
    // 信息回显-----------
    // 基本回显
    getMyInfo(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/getMyinfo`, params)
    },
    // 求职意向回显
    myResumeExpect(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/queryResumeExpect`, params)
    },
    // 教育经历回显
    myResumeEduDtail(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeEduDtail`, params)
    },
    // 语言能力回显
    myResumeLanguageDetail(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeLanguageDetail`, params)
    },
    // 项目经历回显
    myResumeProjectDetail(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeProjectDetail`, params)
    },
    // 专业技能回显
    myResumeSkillDetail(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeSkillDetail`, params)
    },
    // 培训经历回显
    myResumeTrainDetail(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeTrainDetail`, params)
    },
    // 证书经历回显
    myResumeCertDetail(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeCertDetail`, params)
    },
    // 工作经历回显
    myResumeWork(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeWorkDetail`, params)
    },
    // 自我评价
    myResumeDes(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/resumeDescription`, params)
    },
    //   删除------------------------
    // 工作经历删除
    deleteMyResumeWork(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/deleteMyResumeWork`, params)
    },
    // 教育经历删除
    deleteMyResumeEdu(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/deleteMyResumeEdu`, params)
    },
    // 培训经历删除
    deleteMyResumeTrain(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/deleteMyResumeTrain`, params)
    },
    // 专业技能删除
    deleteMyResumeSkill(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/deleteMyResumeSkill`, params)
    },

    // 证书经历删除
    deleteMyResumeCert(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/deleteMyResumeCert`, params)
    },
    // 语言能力删除
    deleteMyResumeLanguage(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/deleteMyResumeLanguage`, params)
    },
    // 项目经历删除
    deleteMyResumeProject(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/deleteMyResumeProject`, params)
    },
    //第三方登录确认
    getListOtherLoginApi(params) {
        return http.get(`${api}/app-jycy-member/getListOtherLogin`, params)
    },
    //第三方解除绑定
    removeOtherLoginApi(params) {
        return http.get(`${api}/app-jycy-member/removeOtherLogin`, params)
    },
    //引导完善简历工作经历回显
    myResumeWorkListApi(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/myResumeWorkList`, params)
    },
}
export default center