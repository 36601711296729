import http from '../plugins/utils/http'
let api = '/api'
const section = {
    //推荐列表
    sectionListApi(params) {
        return http.post(`${api}/app-jycy-zhuanlan/querySpcialClomunList`, params)
    },
    //置顶列表
    querySpcialClomunTopListApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/querySpcialClomunTopList`, params)
    },
    //分类筛选
    selectApi(params) {
        return http.get(`${api}/app-jycy-sysdict/commonDictValue`, params)
    },
    //关注
    focusAuthorApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/saveFocusAuthor`, params)
    },
    //最热
    queryHotApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/querySpcialClomunHostList`, params)
    },
    //作者列表
    getAuthorsApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/queryAuthorList`, params)
    },
    //关注de文章列表
    getListApi(params) {
        return http.post(`${api}/app-jycy-zhuanlan/queryFollowSpcialClomunList`, params)
    },
    //点赞
    collectCloumnApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/saveClomunGood`, params)
    },
    //专栏详情
    sectionDetailApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/querySpcialClomunDetail`, params)
    },
    //评论列表
    queryClomunReplayListApi(params) {
        return http.post(`${api}/app-jycy-zhuanlan/queryClomunReplayList`, params)
    },
    //收藏
    saveClomunCollectionApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/saveClomunCollection`, params)
    },
    //专栏保存
    commentSaveApi(params) {
        return http.post(`${api}/app-jycy-zhuanlan/saveClumunReplay`, params)
    },
    //点赞评论
    saveClomunReplayGoodApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/saveClomunReplayGood`, params)
    },
    //专栏用户中心
    queryAutherCenterApi(params) {
        return http.post(`${api}/app-jycy-zhuanlan/queryAutherCenter`, params)
    },
    //我评论的
    queryCommentClomun(params) {
        return http.get(`${api}/app-jycy-zhuanlan/user/queryCommentClomun`, params)
    },
    //我关注的
    queryFocusClomun(params) {
        return http.get(`${api}/app-jycy-zhuanlan/user/queryFocusClomun`, params)
    },
    //我点赞的
    queryGoodClomun(params) {
        return http.get(`${api}/app-jycy-zhuanlan/user/queryGoodClomun`, params)
    },
    //我收藏的专栏
    querycollectClomun(params) {
        return http.get(`${api}/app-jycy-zhuanlan/user/querycollectClomun`, params)
    },
    //我收藏的活动
    queryCollectionActivity(params) {
        return http.post(`${api}/app-jycy-activity/queryCollectionActivity`, params)
    },
}
export default section
