import http from '../plugins/utils/http'
let api = '/api'
const jobFair = {
    // 专场招聘会列表接口
    getJobFairDzList(params) {
        return http.post(`${api}/app-jycy-jobfair/getJobFairDzList`, params)
    },
    // 招聘会-公司展示列表
    jobFairCompanys(params) {
        return http.post(`${api}/app-jycy-jobfair/jobFairCompanys`, params)
    },
	// 招聘会-公司展示列表
	querySearchCompanyByInfo(params) {
	    return http.post(`${api}/app-jycy-job/querySearchCompanyByInfo`, params)
	},
	// 招聘会-公司展示列表
	querySearchJobInfo(params) {
	    return http.post(`${api}/app-jycy-job/querySearchJobInfo`, params)
	},
    //专场招聘会详情接口
    jobFairDetail(params) {
        return http.get(`${api}/app-jycy-jobfair/jobFairDetail`, params)
    },
    //招聘会-职位展示列表
    jobFairJobs(params) {
        return http.post(`${api}/app-jycy-jobfair/jobFairJobs`, params)
    },
    //招聘会-企业用户查询招聘会列表
    jobfairList(params) {
        return http.post(`${api}/app-jycy-jobfair/company/jobfairList`, params)
    },
    //招聘会详情
    jobfairDetail(params) {
        return http.get(`${api}/app-jycy-jobfair/jobFairDetail`, params)
    },
    //招聘会-企业用户查询招聘会参会职位列表
    jobfairJobList(params) {
        return http.post(`${api}/app-jycy-jobfair/company/jobfairJobList`, params)
    },
    //招聘会-保存参会职位
    joinJobFair(params) {
        return http.post(`${api}/app-jycy-jobfair/company/joinJobFair`, params)
    },
    //招聘会-取消参会
    cancelJoinJobFair(params) {
        return http.post(`${api}/app-jycy-jobfair/company/cancelJoinJobFair`, params)
    },

}
export default jobFair