import Vue from 'vue'
import Vuex from 'vuex'
import login from './login/index'
import recruit from './recruit/index'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        location: '',
        userType:'',
        locationSite:'',
        userPassword:'',
        allCity:'',
    },
    mutations: {
        GET_LOCATION(state, info) {
            localStorage.setItem('locationText', info.itemText);
            // localStorage.setItem('location',JSON.stringify(info));
            state.location = info;
        },
        GET_USER(state,info){
            localStorage.setItem("userType", JSON.stringify(info));
            state.userType = info;
        },
        locationSelect(state,info){
            localStorage.setItem("location", info);
            state.locationSite = info;
        },
        setPassword(state,userPassword){
           state.userPassword = userPassword;
        },
        getAllCity(state,info){
            state.allCity = info;
            localStorage.setItem("locationSite", info);
        },
    },
    actions: {},
    modules: {
        login,
        recruit
    }
})

export default store
