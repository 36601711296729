<template>
  <div class="status">
    <ul>
      <li class="wifi cursorP" v-if=" type == 'wifi'" @click="onRefresh()">
        <img src="@/static/public/no_wifi.png" alt="网络不可用,请检查网络设置" />
        <p>网络不可用,请检查网络设置</p>
      </li>
      <li class="result" v-if=" type == 'result'">
        <img src="@/static/public/no_result.png" :alt="des?des:'暂无记录'" />
        <p>{{des?des:'暂无记录'}}</p>
      </li>
      <li class="result" v-if=" type == 'post'">
        <img src="@/static/public/no_result.png" :alt="des" />
        <p>{{des}}</p>
      </li>
      <li class="result" v-if=" type == 'record'">
        <img src="@/static/public/no_record.png" alt="暂无记录" />
        <p class="no_data">暂无记录</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "status",
  props: {
    type: "",
    des: "",
  },
  methods: {
    onRefresh() {
      this.$router.go(-1); //返回之前点击的页面
    },
  },
};
</script>

<style scoped lang="less">
.status {
  width: 100%;
  min-height: 360px;
  box-sizing: border-box;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    position: relative;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
      p {
        position: absolute;
        text-align: center;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 16px;
        color: gray;
        margin-top: 20px;
      }
      p.no_data{
        bottom: 50px;
      }
    }
  }
}
</style>
