<template>
    <div class="authentication">
        <el-dialog
                :visible.sync="show"
                width="312px"
                :show-close="false"
                :before-close="handleClose">
            <div class="dialog_content">
                <img src="~/static/login/resume.png" alt="">
                <h3>未通过认证的用户 <br>
                    无法享受残疾人专属服务哦</h3>
                <div class="btn flex">
                    <el-button type="info" @click="handleClose()">残忍拒绝</el-button>
                    <el-button type="primary" @click="goRouter">去认证</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "authentication",
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },
        methods: {
            handleClose() {
                this.$emit("close", false);
                // this.show = false;
            },
            goRouter(){
              this.$router.push('/userLogin/authentication')
            },
        }
    }
</script>

<style scoped lang="less">
.el-dialog {
  .dialog_content {
    text-align: center;

    h3 {
      font-size: 18px;
      margin-top: 24px;
    }

    p {
      color: #66716C;
      padding: 6px 0;
    }

    .btn {
      justify-content: center;
      margin-top: 10px;

      .el-button {
        width: 102px;
      }

      .el-button:nth-of-type(1) {
        margin-right: 20px;
      }
    }

    .el-button {
      margin-top: 16px;
      width: 132px;
    }

  }
}
</style>