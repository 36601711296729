import http from '../plugins/utils/http'
let api = '/api'
const enterpriseEnter = {
    // 企业中心
    comCenterBasicInfo(params) {
        return http.get(`${api}/app-jycy-company/com/comCenterBasicInfo`, params)
    },
    // 企业详细信息
    getCompanyInfo(params) {
        return http.get(`${api}/app-jycy-company/com/getCompanyInfo`, params)
    },
    // 上传文件
    uploadImg(params) {
        return http.get(`${api}/app-jycy-disabled/uploadImg `, params)
    },
    // 企业信息编辑
    getCompanyInfos(params) {
        return http.get(`${api}/app-jycy-company/com/getCompanyInfos`, params)
    },
    // 提交审核
    companyNeedAudit(params) {
        return http.post(`${api}/app-jycy-company/com/updateCompanyNeedAudit`, params)
    },
    // 保存编辑的信息
    updateCompany(params) {
        return http.post(`${api}/app-jycy-company/com/updateCompany`, params)
    },
    // 需求列表
    needList(params) {
        return http.post(`${api}/app-jycy-needs/needList`, params)
    },
    // 需求详情
    getNeedById(params) {
        return http.post(`${api}/app-jycy-needs/getNeedById`, params)
    },
    // 删除需求
    updateNeedById(params) {
        return http.post(`${api}/app-jycy-needs/updateNeedById`, params)
    },
    // 提出需求
    addNeed(params) {
        return http.post(`${api}/app-jycy-needs/addNeed`, params)
    },
    // 百家详情
    queryBaijiaDetail(params) {
        return http.get(`${api}/app-jycy-company/baijia/queryBaijiaDetail`, params)
    },

}
export default enterpriseEnter