<template>
  <div id="app">
    <router-view v-if="isShow"></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isShow: true
    }
  },
  methods: {
    reload () {
      this.isShow= false
      this.$nextTick(function () {
        this.isShow= true
      })
    }
  },
  created() {
    let s  = document.createElement('script')
    // s.src = 'https://a11y.oss-cn-hangzhou.aliyuncs.com/toolbar/daily/0.0.4/toolbar.js'
    s.src = 'https://a11y.oss-cn-hangzhou.aliyuncs.com/toolbar/daily/1.0.0/toolbar.js'
    document.body.appendChild(s)
  }
}
</script>
<style lang="less">
.el-message{
  z-index: 12010!important;
}
</style>