import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/utils/http.js'
import BaiduMap from 'vue-baidu-map'
import ElementUI from 'element-ui';
import cdUpload from './components/public/cd-upload';
import api from './api/api.js'
import VueQuillEditor from 'vue-quill-editor'
import authentication from './components/public/authentication'
import VueWechatTitle from 'vue-wechat-title'
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import './assets/css/style.css'
import './assets/css/element.css'
import './theme/element-variables.scss'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.core.css'
import Viewer from 'v-viewer'
// import VueAMap from 'vue-amap'
import formCreate from '@form-create/element-ui'
import 'viewerjs/dist/viewer.css'
import qrCode from './components/public/qr'
import { currentYear, mobile, linkRouter, getChildCity, doSm2Encrypt } from './plugins/data.js'
import { dateFormat,isMobile } from './plugins/utils/utils'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import status from './components/public/status.vue'
import VueClipboard from 'vue-clipboard2'
import { decryption } from './plugins/utils/aesEncrypt'
import MetaInfo from 'vue-meta-info'
// import VueJsonp from 'vue-jsonp'

// Vue.prototype.$jsonp = VueJsonp;
Vue.use(VueClipboard)
Vue.use(formCreate)

Vue.use(MetaInfo)
import waterfall from 'vue-waterfall2'

Vue.use(waterfall)
import VueLazyload from 'vue-lazyload'
Vue.prototype.$dateFormat = dateFormat;  

Vue.use(VueLazyload)
Vue.component('status', status)
Vue.component('qrCode', qrCode)
Vue.component('authentication', authentication)
Vue.component('cdUpload', cdUpload)
Vue.use(VideoPlayer)
Vue.prototype.$decrypt = decryption;
Vue.prototype.$sm2Encrypt = doSm2Encrypt;    //国密sm2加密
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.config.productionTip = false
Vue.use(VueWechatTitle)
Vue.use(BaiduMap, {
    ak: 'yWV9NbSo39sYfFCd45h77myXvrycuIBq'
})
const uploadHeaders = {
    Authorization: 'Bearer ' + localStorage.getItem("access_token")
}
Vue.prototype.uploadHeaders = uploadHeaders;

Storage.prototype.setExpire = (key, value, expire) => {
    let obj = {
        data: value,
        time: Date.now(),
        expire: expire
    };
    localStorage.setItem(key, JSON.stringify(obj));
}
Storage.prototype.getExpire = key => {
    let val = localStorage.getItem(key);
    if (!val) {
        return val;
    }
    val = JSON.parse(val);
    if (Date.now() - val.time > val.expire) {
        localStorage.removeItem(key);
        return null;
    }
    return val.data;
}

// Vue.use(VueAMap);
// 初始化vue-amap
// VueAMap.initAMapApiLoader({
//     // 高德的key
//     key: 'a32698998266bc74d9b9f221f154a072',
//     // 插件集合
//     plugin: ['AMap.Autocomplete', 'AMap.PlaceSearMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geolocation'],
//     // 高德 sdk 版本，默认为 1.4.4ch', 'AMap.Scale', 'A
//     v: '1.4.4'
// });
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
})
Vue.use(VueQuillEditor)
if (process.browser) {
    const VueQuillEditor = require('vue-quill-editor/dist/ssr')
    Vue.use(VueQuillEditor)
}
Vue.use(ElementUI);
//公用JS 去除当前年份
Vue.prototype.$currentYear = currentYear;
//公用js 根据定位去对应子站
Vue.prototype.$getChildCity = getChildCity;

//公用JS 判断是否为移动端
Vue.prototype.$mobile = mobile;
Vue.prototype.$linkRouter = linkRouter;
router.afterEach((to, from, next) => {
    setTimeout(() => {
        var _hmt = _hmt || [];
        window._hmt = _hmt;
        (function () {
            //每次执行前，先移除上次插入的代码
            document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?ee31947220a83dbba5919eb6c496b632";
            hm.id = "baidu_tj"
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }, 0);
});
Vue.filter("handleTime", (val) => {
    if (!val) return ""
    val = val.replace(/\//g, '/')
    return val
})

new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app');
