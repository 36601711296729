import http from '../../plugins/utils/http'

let api = '/api'
const lanzhou = {
    // 残保金审核
    lanzhouGetALLCheckApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getALLCheck`, params)
    },
    //编辑企业信息回显
    lanzhouGetComInfoApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getComInfo`, params)
    },
    //企业信息保存
    lanzhouUpdateComApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/updateCom`, params)
    },
    //安置残疾人就业信息
    lanzhouGetDisabledsApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getDisableds`, params)
    },
    //残疾人就业信息回显
    lanzhouGetDisabledApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getDisabled/`+params )
    },
    //新增残疾人就业信息
    lanzhouUpdateDisabledApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/updateDisabled`, params)
    },
    //删除残疾人就业信息
    lanzhouDeleteDisableApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/deleteDisable/`+params )
    },
    //全员工资表回显
    lanzhouGetAllSalaryApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getAllSalary`, params)
    },
    //更新工资表
    lanzhouUpdateSalaryApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/updateSalary`, params)
    },
    //就业统计表
    lanzhouGetEmployStatisticApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getEmployStatistic`, params)
    },
    //更新就业统计表
    lanzhouUpdateEmployStatisticApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/updateEmployStatistic`, params)
    },
    //社保缴费证明
    lanzhouGetComImgsApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getComImgs`, params)
    },
    //更新社保缴费证明
    lanzhouSetComImgsApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/setComImgs`, params)
    },
    //提交残保金
    lanzhouUpdateAuditstatucApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/updateAuditstatuc`, params)
    },
    //残保金详情
    lanzhouGetComCheckApi(params) {
        return http.post(`${api}/app-jycy-gansu/com/disabledprimum/getComCheck`, params)
    },
    //盲人医疗按摩人员继续教育
    lanzhouHeadPageApi(params) {
        return http.get(`${api}/app-jycy-gansu/blindTrain/headPage`, params)
    },
    //盲人医疗按摩人员继续教育
    lanzhouHeadListApi(params) {
        return http.get(`${api}/app-jycy-gansulz/blindTrain/headPage`, params)
    },
    //盲人医疗按摩人员继续教育回显
    lanzhouQueryByIdApi(params) {
        return http.get(`${api}/app-jycy-gansu/blindTrain/queryById`, params)
    },
    //盲人医疗按摩人员继续教育添加
    lanzhouMangrenAddApi(params) {
        return http.post(`${api}/app-jycy-gansu/blindTrainApply/add`, params)
    },
    //个人中心盲人医疗按摩国家级
    lanzhouList_countryApi(params) {
        return http.get(`${api}/app-jycy-gansu/blindTrainApply/list_country`, params)
    },
    //个人中心盲人医疗按摩省级
    lanzhouList_provinceApi(params) {
        return http.get(`${api}/app-jycy-gansu/blindTrainApply/list_province`, params)
    },
    //盲人医疗按摩人员继续教育回显
    lanzhouCenterQueryByIdApi(params) {
        return http.get(`${api}/app-jycy-gansu/blindTrainApply/queryById`, params)
    },
    //盲人医疗按摩人员继续教育变价
    lanzhouCenterEditBlindApi(params) {
        return http.post(`${api}/app-jycy-gansu/blindTrainApply/edit`, params)
    },
}
export default lanzhou
