import http from '../plugins/utils/http'
let api = '/api'
const news = {
    newsListApi(params) {
        return http.post(`${api}/app-jycy-consultation/getPageListByType`, params)
    },
    updataReadApi(params) {
        return http.get(`${api}/app-jycy-consultation/ConsultationService/updataRead`, params)
    },
    getDetailApi(params) {
        return http.get(`${api}/app-jycy-consultation/ConsultationService/getDetail`, params)
    },
    //首页新闻信息
    getConsulationListApi(params) {
        return http.get(`${api}/app-jycy-consultation/getConsulationList`, params)
    },

}
export default news
