/*
 * @Description: 企业找回密码-审核
 * @Version: 
 * @Autor: houchuanfang
 * @Date: 2022-03-16 16:31:00
 * @LastEditTime: 2022-03-17 11:03:49
 */
export default [
    {
        path: '/companyAudit',
        name:'companyAudit',
        component: () =>
            import ('../../views/companyLogin/audit'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/companyAudit/step2',
        name:'companyAudit_step2',
        component: () =>
            import ('../../views/companyLogin/audit2'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/companyHasRegist',
        component: () =>
            import ('../../views/companyLogin/hasRegist'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/companyLogin/retrieve',
        component: () =>
            import ('../../views/companyLogin/retrieve'),
        meta: {
            title: '找回账号-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    }
]