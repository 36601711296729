import http from '../../plugins/utils/http'
let api = '/api'
const skill = {
    // 赛事列表
    matchListApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanSkillsOmpetition/list`, params)
    },
    //根据证件号查询成绩-个人
    getResultByCodeApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanCompetitionResults/getResultByCode`, params)
    },
    //赛事活动

    //赛事详情
    getDetailByIdApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanSkillsOmpetition/getDetailById`, params)
    },
    //赛事组别报名
    competitionGroupApplyApi(params) {
        return http.post(`${api}/app-jycy-hunan/hunanCompetitionGroupApply/competitionGroupApply`, params)
    },
    //查所有人的成绩
    hunanAchievementApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanCompetitionResults/list`, params)
    },
}
export default skill