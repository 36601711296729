import enterpriseRouter from '../enterprise'
import centerRouter from '../center'
import job from '../../views/job/index'
import loginRouter from './login'
import passRouter from './pass'
import activityRouter from './activity'
import companyAudit from './companyAudit'
export default {
    path: '/index',
    component: () =>
        import ('../../views/Index.vue'),
    children: [{
            path: '/enterprise',
            component: () =>
                import ('../../views/HomeEn'),
            meta: {
                title: '中国残联就业创业服务平台',
                requireAuth: true,
                userType: 2
            }
        },
        ...loginRouter,
        ...passRouter,
        ...activityRouter,
        ...companyAudit,
        {
            path: '/protocol',
            name: 'protocol',
            component: () =>
                import ('../../views/Protocol.vue'),
            meta: {
                title: '信息介绍-中国残联就业创业服务平台',
                requireAuth: false,
                userType: 1
            },
            children: [{
                    path: 'agreement',
                    name: 'agreement',
                    component: () =>
                        import ('../../components/public/AgreeMent.vue'),
                    meta: {
                        title: '隐私政策-中国残联就业创业服务平台',
                        requireAuth: false,
                        userType: 1
                    }

                },
                {
                    path: 'law',
                    name: 'law',
                    component: () =>
                        import ('../../components/public/Law.vue'),
                    meta: {
                        title: '法律声明-中国残联就业创业服务平台',
                        requireAuth: false,
                        userType: 1
                    }

                }, {
                    path: 'userServe',
                    name: 'userServe',
                    component: () =>
                        import ('../../components/public/UserServe.vue'),
                    meta: {
                        title: '法律声明-中国残联就业创业服务平台',
                        requireAuth: false,
                        userType: 1
                    }

                },
                {
                    path: 'barrierFree',
                    name: 'barrierFree',
                    component: () =>
                        import ('../../components/public/BarrierFree.vue'),
                    meta: {
                        title: '无障碍声明-中国残联就业创业服务平台',
                        requireAuth: false,
                        userType: 1
                    }

                },
                {
                    path: 'Introduction',
                    name: 'Introduction',
                    component: () =>
                        import ('../../components/public/Introduction'),
                    meta: {
                        title: '网站简介',
                        requireAuth: false
                    },
                },
                {
                    path: 'aboutUs',
                    name: 'aboutUs',
                    component: () =>
                        import ('../../components/public/aboutUs'),
                    meta: {
                        title: '联系我们',
                        requireAuth: false
                    },
                },
            ]

        },

        {
            path: '/messages',
            component: () =>
                import ('../../views/messages/Index'),
            meta: {
                title: '消息管理-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }

        },
        {
            path: '/job/',
            name: 'job',
            component: () =>
                import ('../../views/job/index.vue'),
            children: [{
                path: '/job/',
                component: () =>
                    import ('../../views/job/jobList.vue'),
                meta: {
                    requireAuth: false,
                    memberType: 1
                },
            },]
        },
        {
            path: '/job/jobDetail',
            name: 'jobDetail',
            component: () =>
                import ('../../views/job/jobDetail'),
            meta: {
                title: '职位详情-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/job/businessDetails',
            name: 'businessDetails',
            component: () =>
                import ('../../views/job/businessDetails'),
            meta: {
                title: '公司详情-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/training/mechanism',
            name: 'mechanism',
            component: () =>
                import ('../../views/training/mechanism'),
            meta: {
                title: '机构列表-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/training/mechanismDetail',
            name: 'mechanismDetail',
            component: () =>
                import ('../../views/training/mechanismDetail'),
            meta: {
                title: '机构详情-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/training/public',
            name: 'public',
            component: () =>
                import ('../../views/training/public'),
            meta: {
                title: '公开课-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/training/publicDetail',
            name: 'publicDetail',
            component: () =>
                import ('../../views/training/publicDetail'),
            meta: {
                title: '公开课详情-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/training/trainList',
            name: 'trainList',
            component: () =>
                import ('../../views/training/trainList'),
            meta: {
                title: '培训列表-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/training/trainDetail',
            name: 'trainDetail',
            component: () =>
                import ('../../views/training/trainDetail'),
            meta: {
                title: '培训详情-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/news/msg_list',
            name: 'msg_list',
            meta: {
                title: '通知列表-兰州市残疾人就业创业网络服务平台',
            },
            component: () =>
                import ('../../views/news/msg_list'),
        },
        {
            path: '/news/new_list',
            name: 'new_list',
            meta: {
                title: '新闻列表-兰州市残疾人就业创业网络服务平台',
            },
            component: () =>
                import ('../../views/news/new_list'),
        },
        {
            path: '/news/people_list',
            name: 'people_list',
            meta: {
                title: '残疾人风采列表-兰州市残疾人就业创业网络服务平台',
            },
            component: () =>
                import ('../../views/news/people_list'),
        },
        {
            path: '/news/policy_list',
            name: 'policy_list',
            meta: {
                title: '政策法规列表-兰州市残疾人就业创业网络服务平台',
            },
            component: () =>
                import ('../../views/news/policy_list'),
        },

        {
            path: '/news/newDetail',
            name: 'newDetail',
            meta: {
                title: '详情页面-兰州市残疾人就业创业网络服务平台',
            },
            component: () =>
                import ('../../views/news/newDetail'),
        },
        {
            path: '/activity_inline',
            name: "inline",
            meta: {
                title: '线上-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
            component: () =>
                import ('../../views/activity/Inline.vue'),
            children: [{
                path: 'activityDetail',
                name: 'activityDetail',
                component: () =>
                    import ('../../views/activity/activityDetail'),
            }]
        }, {
            path: '/activity_outline',
            name: "outline",
            meta: {
                title: '线上活动-中国残联官方残疾人求职网站',
                requireAuth: false
            },
            component: () =>
                import ('../../views/activity/Outline.vue'),

        }, {
            path: '/activity_outline/activity_details',
            name: "activityDetail",
            meta: {
                title: '活动详情页-中国残联官方残疾人求职网站',
                requireAuth: false
            },
            component: () =>
                import ('../../views/activity/activityDetail.vue'),
        },
        {
            path: '/asklib',
            name: 'asklib',
            component: () =>
                import ('../../views/asklib/index'),
            meta: {
                title: '问答社区_兰州市残疾人就业创业网络服务平台——中国残联官方残疾人求职网站',
                requireAuth: false
            }
        },
        {
            path: '/asklib/askDetail',
            name: 'askDetail',
            component: () =>
                import ('../../views/asklib/askDetail'),
            meta: {
                title: '问答社区-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/asklib/askCenter',
            name: 'askCenter',
            component: () =>
                import ('../../views/asklib/askCenter'),
            meta: {
                title: '个人主页-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/section',
            name: 'section',
            component: () =>
                import ('../../views/section/index'),
            meta: {
                title: '专栏-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/section/sectionDetail',
            name: 'sectionDetail',
            component: () =>
                import ('../../views/section/sectionDetail'),
            meta: {
                title: '专栏详情-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/section/sectionCenter',
            name: 'sectionCenter',
            component: () =>
                import ('../../views/section/sectionCenter'),
            meta: {
                title: '专栏用户中心-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        //专栏活动KOL
        {
            path: '/section/activity',
            name: 'sectionCenter',
            component: () =>
                import ('../../views/section/activity/index'),
            meta: {
                title: '逐梦人生绽放风采全国残疾人就业创业故事有奖征文-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/section/activity/articleList',
            name: 'sectionCenter',
            component: () =>
                import ('../../views/section/activity/articleList'),
            meta: {
                title: '文章列表-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            }
        },
        {
            path: '/public/allCity',
            name: 'allCity',
            component: () =>
                import ('../../views/public/allCity'),
            meta: {
                title: '所有城市-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
        },
        {
            path: '/public/resume',
            name: 'resume',
            component: () =>
                import ('../../views/public/resume'),
            meta: {
                title: '查看简历-兰州市残疾人就业创业网络服务平台',
                requireAuth: true
            },
        },
        {
            path: '/public/flexible',
            name: 'flexible',
            component: () =>
                import ('../../views/public/flexible'),
            meta: {
                title: '灵活就业-云客服报名-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
        },
        {
            path: '/public/flexibleList',
            name: 'flexibleList',
            component: () =>
                import ('../../views/public/flexibleList.vue'),
            meta: {
                title: '灵活就业列表-云客服报名-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
        },
        {
            path: '/public/recommendJobList',
            name: 'recommendJobList',
            component: () =>
                import ('../../views/public/recommendJobList'),
            meta: {
                title: '职位推荐列表-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
        },
        {
            path: '/public/recommendJob',
            name: 'recommendJob',
            component: () =>
                import ('../../views/public/recommendJob'),
            meta: {
                title: '每周职位推荐-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
        },
        {
            path: '/public/onlineJobfair',
            name: 'onlineJobfair',
            component: () =>
                import ('../../views/OnlineJobfair/index.vue'),
            meta: {
                title: '线上招聘会-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
        },
        {
            path: '/public/recruitCompany',
            name: 'recruitCompany',
            component: () =>
                import ('../../views/public/recruitCompany.vue'),
            meta: {
                title: '助残日-兰州市残疾人就业创业网络服务平台',
                requireAuth: false
            },
        },

        centerRouter,
        enterpriseRouter
    ]
}
