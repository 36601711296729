const state = {
    // invativeId: JSON.parse(localStorage.getItem("invativeId")) || 1,
    memberId: ''

};


const actions = {

};

const mutations = {

    SET_INVATIVE_ID(state, id) {
        // localStorage.setItem('invativeId', JSON.stringify(id));
        state.memberId = id;
    },

};

export default {

    state,
    actions,
    mutations
}