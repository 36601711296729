import http from '../plugins/utils/http'
let api = '/api'
const recruit = {
    // 请求残疾人相关信息
    getList(params) {
        return http.post(`${api}/app-jycy-disabled/getResumeByApply`, params)
    },
    // 在招职位-刷新
    updateJobStatus(params) {
        return http.post(`${api}/app-jycy-job/updateJobStatus`, params)
    },
    // 职位管理--post
    getPostList(params) {
        return http.post(`${api}/app-jycy-job/getJobListByComCenter`, params)
    },
    // 发布职位请求
    publishList(params) {
        return http.post(`${api}/app-jycy-job/upatePostion`, params)
    },
    // 编辑职位数据回显
    editList(params) {
        return http.get(`${api}/app-jycy-job/getJobEditDetailByid`, params)
    },
    // 找人才侧边我发布的职位
    findPersonnel(params) {
        return http.get(`${api}/app-jycy-job/app-jycy-job/getJobNameListByCompanyId`, params)
    },
    // 更新简历信息
    applyFacing(params) {
        return http.post(`${api}/app-jycy-jobapply/updateInviteInterview`, params)
    },
    // 查看简历请求接口
    addCompanyLookResumeApi(params) {
        return http.get(`${api}/app-jycy-company/addCompanyLookResume`, params)
    },
    // 网梯报名
	wangTiTrainApply(params) {
	    return http.post(`${api}/app-jycy-train/wangTiTrainApply`, params)
	},
    // 校验报名时身份证是否重复
    wangTiTrainApplyIdcardCheck(params) {
	    return http.get(`${api}/app-jycy-train/wangTiTrainApplyIdcardCheck`, params)
	},
        // 校验报名时身份证是否重复
    wangTiTrainApplyTips(params) {
	    return http.get(`${api}/app-jycy-train/wangTiTrainApplyTips`, params)
	},
    // 收藏
    collectResume(params) {
        return http.post(`${api}/app-jycy-company/collectResume`, params)
    },
    // 我收到的简历--resumes
    getReceiveList(params) {
        return http.post(`${api}/app-jycy-jobapply/com/queryJobApplyRecord`, params)
    },
    // 我收到的简历各类型数量--resumes
    getReceiveCount(params) {
        return http.get(`${api}/app-jycy-jobapply/com/queryJobApplyCountByCom`, params)
    },
    // 我收到的简历--resumes
    getCollectList(params) {
        return http.post(`${api}/app-jycy-company/com/collectionResumes`, params)
    },
    // 移除收藏
    getRemoveCollectList(params) {
        return http.post(`${api}/app-jycy-company/com/collectResumeBatch`, params)
    },

}
export default recruit