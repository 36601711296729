import http from '../plugins/utils/http'
let api = '/api'
const optimize = {
    // 首页职位搜索框，根据关键词搜索职位接口--jobName
    queryLikeJobInfo(params) {
        return http.get(`${api}/app-jycy-disabled/getMobileOrTel`, params)
    },
    // 残疾人投递简历前，职位匹配校验接口
    disAbleJobRemind(params) {
        return http.get(`${api}/app-jycy-job/disAbleJobRemind`, params)
    },
    // 消息模块列表
    queryNoticesByType(params) {
        return http.get(`${api}/app-jycy-usernotice/queryNoticeByType`, params)
    },
    // 消息数量
    queryUserMsgCount(params) {
        return http.get(`${api}/app-jycy-usernotice/queryUserMsgCount`, params)
    },
    // 广告点击量
    updateAdClick(params) {
        return http.get(`${api}/app-jycy-ad/updateAdClick`, params)
    },
    // 灵活就业
    queryLhjyActivityList(params) {
        return http.get(`${api}/app-jycy-activity/queryLhjyActivityList`, params)
    },


}
export default optimize