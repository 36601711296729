import http from '../plugins/utils/http'
let api = '/api'
const login = {
    //登录
    getLoginApi(params) {
        return http.post(`${api}/app-jycy-member/login`, {...params, registerSite: 'lz'})
    },
    //登出
    outLoginApi(params) {
        return http.get(`${api}/app-jycy-member/logout`, params)
    },
    //登录账号验证
    getfindIphoneApi(params) {
        return http.get(`${api}/app-jycy-member/findIphone`, params)
    },
    //图形验证
    loginImageApi(params) {
        return http.get(`${api}/code`, params)
    },
    //认证后刷新登录信息
    loginRefreshApi(params) {
        return http.get(`${api}/app-jycy-member/refresh`, params)
    },
    //简历完善度
    loginIfFullApi(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/ifFull`, params)
    },
    // 残疾人认证
    checkIdcard(params) {
        return http.post(`${api}/app-jycy-security/user/checkIdcard`, params)
    },
    // 发送验证码
    phoneVerification(params) {
        return http.post(`${api}/app-jycy-phoneverificationcode/phoneVerification`, params)
    },
    // 企业认证
    checkCompanyName(params) {
        return http.post(`${api}/app-jycy-security/user/checkCompanyName`, params)
    },
    // 残疾人注册
    disableRegister(params) {
        return http.post(`${api}/app-jycy-security/user/register`, params)
    },
    // 企业注册
    companyRegister(params) {
        return http.post(`${api}/app-jycy-security/user/companyRegister`, params)
    },
    // 找回密码
    findPwd(params) {
        return http.post(`${api}/app-jycy-member/findPwd`, params)
    },
    //发送邮箱找回密码
    findPwdByEmail(params) {
        return http.get(`${api}/app-jycy-disabled/findPwdByEmail`, params)
    },
    //邮箱找回密码
    resetPwdByEmail(params) {
        return http.get(`${api}/app-jycy-member/resetPwdByEmail`, params)
    },
    // 申诉
    verifyDisabledCode(params) {
        return http.post(`${api}/app-jycy-member/verifyDisabledCode`, params)
    },
    // 残疾人引导页
    selectCompletenesss(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/selectCompletenesss`, params)
    },
    //残疾人认证
    userAuthenticationApi(params) {
        return http.post(`${api}/app-jycy-security/user/authentication`, params)
    },
    //企业认证
    companyAuthenticationApi(params) {
        return http.post(`${api}/app-jycy-security/user/companyAuthentication`, params)
    },
    //查询公司是否已被注册
    getCompanyByNameApi(params) {
        return http.post(`${api}/app-jycy-company/getCompanyByName`, params)
    },
    //找回公司账号-资料上传
    getSubmitImgUrlApi(params) {
        return http.post(`${api}/app-jycy-company/submitImgUrl`, params)
    },
}
export default login