export default [
    {
        path: '/userLogin/authentication',
        component: () =>
            import ('../../views/userLogin/authentication'),
        meta: {
            title: '残疾人认证-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/userLogin/userInfo',
        component: () =>
            import ('../../views/userLogin/userInfo'),
        meta: {
            title: '完善简历-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/userLogin/userApply',
        component: () =>
            import ('../../views/userLogin/userApply'),
        meta: {
            title: '完善简历-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/userLogin/userWork',
        component: () =>
            import ('../../views/userLogin/userWork'),
        meta: {
            title: '完善简历-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/companyLogin/authentication',
        component: () =>
            import ('../../views/companyLogin/authentication'),
        meta: {
            title: '企业信息认证-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
	{
	    path: '/companyLogin/authenticationrz',
	    component: () =>
	        import ('../../views/companyLogin/authenticationrz'),
	    meta: {
	        title: '企业信息认证-中国残联就业创业服务平台',
	        requireAuth: true,
	        userType: 2
	    },
	},
]
