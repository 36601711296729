import http from '../../plugins/utils/http'
let api = '/api'
const create = {
    // 残疾人创业-热门项目
    createProjectApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessPoject/list`, params)
    },
    //残疾人创业-创业基地
    hunanBaseListApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessBase/list`, params)
    },
    //项目详情
    hunanProjectGetDetailById(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessPoject/getDetailById`, params)
    },
    //基地详情
    hunanBaseDetailApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessBase/getDetailById`, params)
    },
    //基地项目列表
    getBusinessPojectByIdApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessBase/getBusinessPojectById`, params)
    },
    //项目入驻
    businessPojectApplyApi(params) {
        return http.post(`${api}/app-jycy-hunan/hunanBusinessPojectApply/businessPojectApply`, params)
    },
    //所属基地列表
    baseSelectListApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessBase/selectList`, params)
    },
    //残疾人创业-活动列表
    hunanActivityListApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessActivity/list`, params)
    },
    //残疾人创业-活动详情
    hunanActivityGetDetailByIdApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessActivity/getDetailById`, params)
    },
    //残疾人创业-活动报名
    hunanActivityApplyApi(params) {
        return http.post(`${api}/app-jycy-hunan/hunanBusinessActivityApply/businessActivityApply`, params)
    },
}

export default create