import http from '../plugins/utils/http'
let api = '/api'
const hunan = {
    //湖南首页
    hunanGetSiteDataApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunan/getSiteData`, params)
    },
    //湖南首页更新
    hunanUpdateSiteDataApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunan/updateSiteData`, params)
    },
    // 盲按专题信息
    massageListApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanMangan/getManganZhuanTiXiangQing`, params)
    },
    // 心理咨询预约和物理测评预约机构
    appointListApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanYuyueXinli/list`, params)
    },
    // 心理咨询预约
    orderApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanYuyueXinliAplly/yuyueXinliAplly`, params)
    },
    // 物理测评预约
    evaluateOrderApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanYuyueWuliAplly/yuyueXinliAplly`, params)
    },
    //盲按专题列表
    manganListApi(params) {
        return http.get(`${api}/app-jycy-hunan/hunanMangan/list`, params)
    },
    //盲按专题查看更多
    manganSeeMore(params) {
        return http.get(`${api}/app-jycy-hunan/hunanMangan/gengDuo`, params)
    },
    //盲按文章详情
    articleDetail(params) {
        return http.get(`${api}/app-jycy-hunan/hunanMangan/getDetailById`, params)
    },
    //企业中心-我的创业-创业项目入驻
    createProject(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessActivity/getMyBusinessBase`, params)
    },
    //企业中心-我的创业-报名的创业活动
    createActivity(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessActivity/getMyBusinessActivity`, params)
    },
    //企业中心-我的竞赛
    skillContest(params) {
        return http.get(`${api}/app-jycy-hunan/hunanBusinessActivity/getMySkillsOmpetition`, params)
    },
}
export default hunan