import http from '../plugins/utils/http'
let api = '/api'
const home = {
    zhiboActivityListApi(params) {
        return http.get(`${api}/app-jycy-activity/getZhiboActivityList`, params)
    },
    //职位列表
    querySearchJobInfoApi(params) {
        return http.get(`${api}/app-jycy-job/querySearchJobInfo`, params)
    },
    // 推荐企业
    hotCompany(params) {
        return http.get(`${api}/app-jycy-company/com/hotCompany`, params)
    },
    //企业点击量
    updateCompanysClick(params) {
        return http.get(`${api}/app-jycy-company/com/updateCompanysClick`, params)
    },
    //求职招聘模糊搜索
    v2blurryJobName(params) {
        return http.get(`${api}/app-jycy-job/v2blurryJobName`, params)
    },
    //求职招聘模糊搜索
    v2blurryCompanyNameCount(params) {
        return http.get(`${api}/app-jycy-job/v2blurryCompanyNameCount`, params)
    },
    //关键企业
    v2getCompanyListByName(params) {
        return http.get(`${api}/app-jycy-company/v2getCompanyListByName`, params)
    },
    // 热门企业
    hotCompanyss(params) {
        return http.get(`${api}/app-jycy-company/com/hotCompanys`, params)
    },
    // /app-jycy-job/v2hotSearchWord
    v2hotSearchWord(params) {
        return http.get(`${api}/app-jycy-job/v2hotSearchWord`, params)
    },
    v2hotSearchWordHits(params) {
        return http.get(`${api}/app-jycy-job/v2hotSearchWordHits`, params)
    },
    //首页接口-轮播图
    getHomeRotationApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeRotation`, params)
        return http.get(`${api}/app-jycy-ad/getHomeRotation`, params)
    },
    //首页接口-新闻动态
    getHomelnformationApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomelnformation`, params)
        return http.get(`${api}/app-jycy-consultation/getHomeInformationList`, params)
    },
    //首页接口-政策法规
    getHomeRegulationsApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeRegulations`, params)
        return http.get(`${api}/app-jycy-consultation/getHomeRegulations`, params)
    },
    //首页接口-通知公告
    getHomeNoticeApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeNotice`, params)
        return http.get(`${api}/app-jycy-consultation/getHomeNotice`, params)
    },
    //首页接口-推荐职位
    getHomeJobsApi(params) {
        return http.get(`${api}/app-jycy-job/getHomeJob`, params)
        // return http.get(`${api}//api/app-jycy-job/querySearchJobInfo`, params)
    },
    //首页接口-职业培训
    getHomeTrainApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeTrain`, params)
        return http.get(`${api}/app-jycy-train/getHomeTrain`, params)
    },
    //首页接口-公开课
    getHomeOpenCourseApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeOpenCourse`, params)
        return http.get(`${api}/app-jycy-train/getHomeOpenCourse`, params)
    },
    //首页接口-问答
    getHomeAsksApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeAsks`, params)
        return http.get(`${api}/app-jycy-ask/getHomeAsks`, params)
    },
    //首页接口-热门回答榜
    getHomeAnswerMemberApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeAnswerMember`, params)
        return http.get(`${api}/app-jycy-ask/getHomeAnswerMember`, params)
    },
    //首页接口-人物风采
    getHomeCjrfcsApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeCjrfcs`, params)
        return http.get(`${api}/app-jycy-consultation/getHomeCjrfcs`, params)
    },
    //首页接口-残疾人作品展销
    productList(params){
        return http.post(`${api}/app-jycy-gansulz/product/list`, params)
    },
    //首页接口-合作单位
    getHomeHzdwApi(params) {
        // return http.get(`${api}/app-jycy-usernotice/getHomeHzdw`, params)
        return http.get(`${api}/app-jycy-ad/getHomeHzdw`, params)
    },
    //轨迹
    memberOperLogApi(params) {
        return http.get(`${api}/app-jycy-member/memberOperLog`, params)
    },
    //登录后确认来源
    addSourceByNewUserApi(params) {
        return http.get(`${api}/app-jycy-member/addSourceByNewUser`, params)
    },
}
export default home
