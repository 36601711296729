/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import {Message, loading} from 'element-ui';
//1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--这里注意后面会讲
    baseURL: process.env.BASE_API,
    // 超时时间 单位是ms，这里设置了20s的超时时间
    timeout: 50 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    let locationSite = localStorage.getItem('locationSite');
    config.data = JSON.stringify(config.data);
    if (config.method == 'get') {
        config.headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    } else {
        config.headers = {
            'Content-Type': 'application/json'
        }
    }
    config.headers['Area-Tag'] = 'LanZhou';
    config.headers['Provinceid'] = 'p24';
    if (localStorage.getItem("access_token") && config.url != '/api/app-jycy-member/login') {
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
    }
    return config
}, error => {
    Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.data.msg == '未登录' || response.data.message == '未登录'){
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("userType");
        window.location.href = '/Login/';
    }
    return response
}, error => {
    let err = '';
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                err = '错误请求400'
                break;
            case 401:
                err = ''
                break;
            case 403:
                err = '拒绝访问'
                break;
            case 404:
                err = '请求404错误,未找到该资源'
                // window.location.href = "/NotFound"
                break;
            case 405:
                err = '请求方法未允许'
                break;
            case 408:
                err = '请求超时'
                break;
            case 500:
                err = '服务器端出错'
                break;
            case 501:
                err = '网络未实现'
                break;
            case 502:
                err = '网络错误'
                break;
            case 503:
                err = '服务不可用'
                break;
            case 504:
                err = '网络超时'
                break;
            case 505:
                err = 'http版本不支持该请求'
                break;
            default:
                err = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            console.log('您的网络不稳定，请确认当前网络状态！')
        }
        err = '连接服务器失败'
    }
    // 未授权，请重新登录
    if (error.response.status == 401) {
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("userType");
        window.location.href = '/Login/';
        let err_data = error.response.data;
        if (err_data.error && err_data.error_description) {
            if (err_data.error_description.indexOf("Access token expired") != -1) {
                console.log(err_data)
                //token过期了，需要使用refresh_token重新获取token
                let refresh_token = localStorage.getItem("refresh_token");
                let getToken = axios.get(
                    "/api/app-jycy-security/oauth/token?grant_type=refresh_token&client_id=app&client_secret=client_secret&refresh_token=" +
                    refresh_token
                ).then((res) => {
                    console.log("refresh_token", res);
                })

            }
        }
        err = '未授权，请重新登录';
    }
    if (error.response.data.message == '未登录' || error.response.data.msg == '未登录') {
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("userType");
        err = '登录已过期';
        // this.$router.push("/Login");
        console.log(err)
        window.location.href = '/Login';
    }
    if (err) {
        // Message.error(err)
    }
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
})
//4.导入文件
export default service
