// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
const sm2 = require('sm-crypto').sm2;
const publicKey = '042c14147adcc71e933617cf2061c7bff57a4a783cb4454f94a63eda7d62ccde665c018463560694f1cda5206882ce84b22d8464ced93bec66931d7b92db0d6c8f';
const mode = 0;
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

export function formatTimeToStr(times, pattern) {
    var d = new Date(times).Format("yyyy-MM-dd hh:mm:ss");
    if (pattern) {
        d = new Date(times).Format(pattern);
    }
    return d.toLocaleString();
}

export function currentYear(time) {
    let date = new Date();
    let years = date.getFullYear();
    let createTime = "";
    if (time) {
        if (time.slice(0, 4) == years) {
            createTime = time.split(years + "年").join("");
        } else {
            createTime = time;
        }
    }
    return createTime;
}

export function linkRouter(url, query) {
    console.log(query)
    if (url) {
        const {href} = this.$router.resolve({
            path: url,
            query: query
        })
        return window.open(href, "_blank");
    }
}

//根据定位选择是否跳到子站
export function getChildCity(province, select) {
    let router = this.$router;
    if (province === select) {
        return
    } else {
        if (!select) {
            if (province == '湖南省') {
                router.push('/hunan');
            } else if (province == '河南省') {
                router.push('/henan');
            } else if (province == '陕西省') {
                router.push('/shanxi');
            }
            else if (province == '甘肃省'){
                router.push('/');
            }
            localStorage.setItem('locationProvince', province);
            localStorage.setItem('locationSite', province);
            this.$store.commit('locationSelect', province);
            localStorage.setItem('locationSelect', '');
        }
    }
}
//sm2加密
export function doSm2Encrypt(val){
    let encryptData = sm2.doEncrypt(val, publicKey, mode);
    // doSm2DoDecrypt((`04${encryptData}`));
    return `04${encryptData}`// sm2加密
}
export function doSm2DoDecrypt(val){
    let encryptData = sm2.doDecrypt(val, "52760264eed2193c58c553579c08fb2203cc21e43ed8428cfba688b2e7c4120c", mode);
    console.log(val,'解密',encryptData,'sss')
    return encryptData// sm2解密
}
//判断是否移动端页面
export function mobile(url) {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        if (url) {
            window.location.href = url;
        } else {
            window.location.href = "https://lzmz.cdpee.org.cn/#/pages/index";
        }
    }
}
