import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from 'element-ui'
import api from '../api/api.js'
import store from '../store'
import homeRouter from './home'
import lanzhouRouter from './lanzhou'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes: [
        homeRouter,
        lanzhouRouter,
        {
            path: '*',
            component: () =>
                import ('../views/error/error_404'),
        },
        {
            path: '/500',
            component: () =>
                import ('../views/error/error_500'),
        },
        // {
        //     path: '/home',
        //     redirect: '/',
        //     name: "home",
        //     meta: {
        //         title: '兰州市残疾人就业创业网络服务平台——中国残联官方残疾人求职网站',
        //         requireAuth: false
        //     },
        // },
        {
            path: '/home',
            redirect: '/',
            component: () =>
                import ('../views/lanzhou.vue'),
            meta: {
                title: '兰州市残疾人就业创业网络服务平台——中国残联官方残疾人求职网站',
                requireAuth: false
            },
        },

        {
            path: '/',
            component: () =>
                import ('../views/lanzhou.vue'),
            meta: {
                title: '兰州市残疾人就业创业网络服务平台——中国残联官方残疾人求职网站',
                requireAuth: false,
                userType: 1

            },
        },
        {
            path: '/Login',
            component: () =>
                import ('../views/Login'),
            title: '残疾人登录',
            meta: {
                title: '残疾人登录',
                requireAuth: false,
            }
        },
        {
            path: '/user/companyLogin',
            component: () =>
                import ('../views/company_login'),
            title: '企业登录',
            meta: {
                title: '企业登录',
                requireAuth: false,
            }
        },
        // {
        //     path: '/user/register',
        //     component: () =>
        //         import ('../views/Register'),
        //     meta: {
        //         title: '注册',
        //         requireAuth: false
        //     }
        // },
        {
            path: '/user/password',
            component: () =>
                import ('../components/public/FindPassword'),
            meta: {
                title: '注册',
                requireAuth: false
            }
        },
        {
            path: '/weixinLogin',
            component: () =>
                import ('../views/userLogin/weixinLogin'),
            meta: {
                title: '微信登录',
                requireAuth: false
            }
        },{
            path: '/videoJob',
            name: 'videoJob',
            component: () =>
                import ('../views/VideoJob.vue'),
            meta: {
                title: '网络招聘会',
                requireAuth: false
            },
        },
		{
		    path: '/jobVideo',
		    name: 'flexible',
		    component: () =>
		        import ('../views/flexible.vue'),
		    meta: {
		        title: '灵活就业',
		        requireAuth: false
		    },
		},
        {
            path: '/public/jobFairIntroduction',
            name: 'jobFairIntroduction',
            component: () =>
                import ('../views/public/jobFairIntroduction.vue'),
            meta: {
                title: '企业-企业招聘会指南',
                requireAuth: false
            },
        },
        {
            path: '/test',
            name: 'test',
            component: () =>
                import ('../views/test'),
            meta: {
                title: '地图测试',
                requireAuth: false
            },
        },
        {
            path: '/question',
            name: 'question',
            component: () =>
                import ('../views/question/question'),
            meta: {
                title: '问卷调查',
                requireAuth: false
            },
        },
        {
            path: '/actionPlan',
            name: 'actionPlan',
            component: () =>
                import('../views/actionPlan/index'),
            meta: {
                title: '',
                requireAuth: false
            },
        },
        {
            path: '/actionPlan/success',
            name: 'actionPlanSuccess',
            component: () =>
                import('../views/actionPlan/success'),
            meta: {
                title: '',
                requireAuth: false
            },
        },

    ],
})

// router.beforeEach((to, from, next) => {
//     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
//     console.log(userInfo, 'vue-router')
//     if (to.meta.requireAuth == true) { //需要登录权限进入的路由
//         if (!localStorage.getItem('access_token')) { //取不到登录信息
//             // alert('当前操作不安全！请登录后进行操作！')
//             next({
//                 path: "/Login",
//                 query: { redirect: to.fullPath }
//             })
//         } else { //取得到登录信息，进行下一步
//             if (userInfo.userType == 1) { //判断路由来源是否有query，处理不是目的跳转的情况
//                 if (to.meta.requireAuth == true && to.meta.userType !== 1) {
//                     next({
//                         path: "/Login",
//                     })
//                 } else {
//                     next();
//                 }
//             }
//             if (userInfo.userType == 2) {
//                 if (to.meta.requireAuth == true && to.meta.userType !== 2) {
//                     next({
//                         path: "/Login",
//                     })
//                 } else {
//                     next();
//                 }
//             }
//         }
//     } else { //不需要登录权限
//         return next();
//     }
// })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach(async (to, from, next) => {
    if (to.query.token) {
        localStorage.setItem('access_token', to.query.token);
        // 刷新个人信息
        let res = await api.loginRefreshApi();
        if (res.data.success) {
            let user = res.data.data;
            let userInfo = user;
            store.commit("SET_USER_INFO", userInfo);
            if (userInfo.userType === "2") {
                next({
                    path: "/enterprise",
                })
            } else {
                next({
                    path: "/",
                })
            }
        } else {
            Message.error('登录失效')
            next('/');
            
        }
    }else{
        next();
    }
})

export default router
