import http from '../plugins/utils/http'
let api = '/api'
const work = {
    // 简历管理--残疾人
    getDisableJobApply(params) {
        return http.get(`${api}/app-jycy-jobapply/disabled/getDisableJobApply`, params)
    },
    // 求职记录--残疾人
    accexpOrjujue(params) {
        return http.get(`${api}/app-jycy-jobapply/accexpOrjujue`, params)
    },
    // 求职记录--残疾人投递资格
    disAbleJobRemind(params) {
        return http.get(`${api}/app-jycy-job/disAbleJobRemind`, params)
    },


}
export default work