import http from '../plugins/utils/http'
let api = '/api'
const asklib = {
    askListApi(params) {
        return http.get(`${api}/app-jycy-ask/getNewAskPageList`, params)
    },
    askHotListApi(params) {
        return http.get(`${api}/app-jycy-ask/getHotAskPageList`, params)
    },
    addQuestionApi(params) {
        return http.post(`${api}/app-jycy-ask/addAsk`, params)
    },
    readApi(params) {
        return http.get(`${api}/app-jycy-ask/addAskHits`, params)
    },
    detailApi(params) {
        return http.get(`${api}/app-jycy-ask/getAskDetail`, params)
    },
    detailGuanApi(params) {
        return http.get(`${api}/app-jycy-ask/addCyAskGuan`, params)
    },
    addAnswerApi(params) {
        return http.post(`${api}/app-jycy-ask/addAnswer`, params)
    },
    answerPageListApi(params) {
        return http.get(`${api}/app-jycy-ask/getAnswerPageList`, params)
    },
    answerDianzanApi(params) {
        return http.get(`${api}/app-jycy-ask/answerDianzan`, params)
    },
    commentPageListApi(params) {
        return http.get(`${api}/app-jycy-ask/getCommentPageList`, params)
    },
    addCommentApi(params) {
        return http.post(`${api}/app-jycy-ask/addComment`, params)
    },
    commentDianzanApi(params) {
        return http.get(`${api}/app-jycy-ask/commentDianzan`, params)
    },
    // 我提问的
    queryMyAsk(params) {
        return http.get(`${api}/app-jycy-ask/queryMyAsk`, params)
    },
    // 我回答的
    queryMyAskAnswer(params) {
        return http.get(`${api}/app-jycy-ask/queryMyAskAnswer`, params)
    },
    // 我评论的
    queryMyAnswerComment(params) {
        return http.get(`${api}/app-jycy-ask/queryMyAnswerComment`, params)
    },
    // 我点赞的
    queryMyDianZan(params) {
        return http.get(`${api}/app-jycy-ask/queryMyDianZan`, params)
    },
    // 我关注的
    queryMyCyAskGuan(params) {
        return http.get(`${api}/app-jycy-ask/queryMyCyAskGuan`, params)
    },
}
export default asklib