<template>
  <div class="job">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: "job",
  metaInfo() {
    return {
      meta: this.meta,
    };
  },
  data() {
    return {
      meta:[],
      location: '',
    }
  },
  methods: {
    getLocation() {
      this.location = localStorage.getItem('locationSite') || '';
      if (this.location == '') {
        document.title = '残疾人求职招聘-兰州市残疾人就业创业网络服务平台';
        this.meta.push(
            {
              name: "keywords",
              content: '残疾人招聘信息,残疾人求职信息,残疾人最新招聘',
            },
            {
              name: "description",
              content: '兰州市残疾人就业创业网络服务平台为残疾人提供真实准确的求职信息及公司简介,帮助残疾人更好更快更全面地了解行业招聘信息。残疾人求职、找工作,上兰州市残疾人就业创业网络服务平台！获得理想求职体验。'
            }
        );
      } else if (this.location == '陕西省') {
        document.title = '残疾人求职招聘找工作-陕西省残疾人就业创业网络服务平台';
        this.meta.push(
            {
              name: "keywords",
              content: '陕西残疾人招聘信息,陕西残疾人找工作,陕西省残疾人,残疾人求职信息,陕西省残疾人最新招聘',
            },
            {
              name: "description",
              content: '陕西省残疾人就业创业网络服务平台为陕西省残疾人提供真实准确的求职信息及公司简介,帮助本省残疾人更好更快更全面地了解行业招聘信息。陕西省残疾人求职、找工作,上陕西省残疾人就业创业网络服务平台！获得理想求职体验。'
            }
        );
      } else if (this.location == '甘肃省') {
        document.title = '残疾人求职招聘找工作-兰州市残疾人就业创业网络服务平台';
        this.meta.push(
            {
              name: "keywords",
              content: '兰州残疾人招聘信息,兰州残疾人找工作,兰州市残疾人,残疾人求职信息,兰州市残疾人最新招聘',
            },
            {
              name: "description",
              content: '兰州市残疾人就业创业网络服务平台为兰州市残疾人提供真实准确的求职信息及公司简介,帮助本市残疾人更好更快更全面地了解行业招聘信息。兰州市残疾人求职、找工作,上兰州市残疾人就业创业网络服务平台！获得理想求职体验。'
            }
        );
      }
    }
  },
  created() {
    this.$nextTick(() =>{
      this.getLocation();
    })
  }
};
</script>

<style scoped lang="less">
</style>
