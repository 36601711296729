export default [
    {
        path: '/retrievePassword',
        component: () =>
            import ('../../views/retrievePassword/index'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/retrievePassword/phone',
        component: () =>
            import ('../../views/retrievePassword/phone'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/retrievePassword/email',
        component: () =>
            import ('../../views/retrievePassword/email'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/retrievePassword/info',
        component: () =>
            import ('../../views/retrievePassword/info'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/retrievePassword/pass',
        component: () =>
            import ('../../views/retrievePassword/pass'),
        meta: {
            title: '找回密码-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
]