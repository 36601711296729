<template>
  <div class="get_upload">
    <el-upload
        class="avatar-uploader uploadimgs upload-demo"
        :class="className"
        :action="isLogo?'':'/api/app-jycy-disabled/uploadImg'"
        list-type="picture-card"
        :show-file-list="false"
        :http-request="getUpload"
        :on-error="erroupload"
        :accept="accept"
        :before-upload="beforeAvatarUpload"
    >
      <img v-if="logo" :src="logo" class="img_avatar block_inline"/>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div slot="tip" class="el-upload__tip" v-if="tips">{{ tips }}</div>
    <el-dialog
        :visible.sync="dialogUpload"
        v-if="dialogUpload"
        width="60%"
        :before-close="close">
      <cropper-upload :img-file="logo" :auto-crop-width="146" :auto-crop-height="146"
                      @upload="getUpload($event,'logo')" @close="close"/>
    </el-dialog>
  </div>
</template>

<script>
import cropperUpload from "@/components/public/cropperUpload";
import axios from 'axios'
export default {
  name: "cd-upload",
  components:{cropperUpload},
  //定义实现v-modal的属性与事件
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    accept: {
      type: String,
      default: '.png,.jpg'
    },
    tips:{
      type: String,
      default: '只能上传jpg/png文件，且不超过2MB，建议上传要求尺寸展示会更好哦'
    },
    isLogo:{     //打开裁剪框
      type:Boolean,
      default:false
    },
    value: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    },
    fileList: {
      type: Array,
      default() {
        return [];
      }
    },
    showFileList: {
      type:Boolean,
      default:false
    },
    limit: {
      type:Number,
      default:1
    },
  },
  data() {
    return {
      dialogUpload:false,
      logo:'',
    }
  },
  watch:{
    value:function (val){
      this.logo = val;
    },
  },
  methods: {
    getUpload(file, type) {
      let src = type ? file : file.file;
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem("access_token")
        }
      }
      let files = new FormData();
      files.append("file", src)
      axios.post('/api/app-jycy-disabled/uploadImg', files, config).then(res => {
        this.logo = res.data.msg;
        this.$emit('change',this.logo)
        if(this.isLogo){
          this.dialogUpload = type ? false : true;
        }
      })
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true
      });
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    handeleRemove(file, fileList) {
      let newList = [];
      fileList.forEach((res)=>{
        let nowUrl = res.url;
        if(res.response){
          nowUrl = res.response.msg;
        }
        let newBean = {};
        newBean["environmentalUrl"] = nowUrl;
        newList.push(newBean);
      })
      this.companyEnvironmentalInDTOList = newList;
    },
    close() {
      this.dialogUpload = false;
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .avatar-uploader {
  position: relative;
  display: inline-block;
  //border: 1px solid #ddd;
  background: #f9f9f9;
  border-radius: 6px;
  cursor: pointer;
  //overflow: hidden;
  .el-upload,.el-upload--text {
    display: block !important;

  }

  img {
    width: 100%;
    height: 100%;
  }

  .el-icon-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #00924B;
  }
}
/deep/.avatars{
  .el-upload {
    display: block !important;
    width: 100%;
    height: 100%;
  }
}
</style>
