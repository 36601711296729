/*
 * @Description:
 * @Version:
 * @Autor: houchuanfang
 * @Date: 2022-07-08 14:34:14
 * @LastEditTime: 2022-07-08 14:39:07
 */
export default {
    path: '/index',
    component: () =>
        import('../../views/Index.vue'),
    children: [{
            path: '/train',
            component: () =>
                import('../../views/lanzhou-cdpe/train.vue'),
            meta: {
                title: '兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/news',
            component: () =>
                import('../../views/lanzhou-cdpe/news.vue'),
            meta: {
                title: '政策要闻——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/blind',
            component: () =>
                import('../../views/lanzhou-cdpe/blind/index.vue'),
            meta: {
                title: '盲人按摩——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/blind/detail',
            component: () =>
                import('../../views/lanzhou-cdpe/blind/detail.vue'),
            meta: {
                title: '盲人按摩——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/blind/blindEdit',
            component: () =>
                import('../../views/lanzhou-cdpe/blind/blindEdit.vue'),
            meta: {
                title: '盲人按摩——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/contentDetail/detail',
            component: () =>
                import('../../views/contentDetail/detail.vue'),
            meta: {
                title: '详情',
                requireAuth: false,
                userType: 1
            }
        },
        {
            path: '/evaluation',
            component: () =>
                import('../../views/lanzhou-cdpe/evaluation/index.vue'),
            meta: {
                title: '能力测评——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/shoppingMall',
            component: () =>
                import('../../views/lanzhou-cdpe/shoppingMall/Index.vue'),
            meta: {
                title: '残疾人作品商品——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/shoppingMall/detail',
            component: () =>
                import ('../../views/lanzhou-cdpe/shoppingMall/detail'),
            meta: {
                title: '残疾人作品商品详情——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },
        {
            path: '/center/orderMange',
            component: () =>
                import ('../../views/lanzhou-cdpe/center/orderMange'),
            meta: {
                title: '订单管理——兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 1
            },
        },

    ]
}
