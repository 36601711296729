import http from '../plugins/utils/http'
let api = '/api'
const activity = {
    // 线上活动
    inlineActivityList(params) {
        return http.get(`${api}/app-jycy-activity/queryPlatformActivityList`, params)
    },
    // 线下活动
    outlineActivityList(params) {
        return http.get(`${api}/app-jycy-activity/queryTopicActivityList`, params)
    },
    // 线下活动详情页
    outlineListDetails(params) {
        return http.get(`${api}/app-jycy-activity/queryTopicActivityDetails`, params)
    },
    // 收藏职位
    collectActivity(params) {
        return http.get(`${api}/app-jycy-activity/collectcActivity`, params)
    },
    // 活动报名
    addTopicActivity(params) {
        return http.get(`${api}/app-jycy-activity/addTopicActivity`, params)
    },
    //我的预约
    getMyapplyTest(params) {
        return http.post(`${api}/app-jycy-henan/henan/psycho/appoint/list`, params)
    },
    //kol作者注册
    kolauthorKOLRegister(params) {
        return http.post(`${api}/app-jycy-member/authorKOLRegister`, params)
    },
    //kol文章列表
    kolgetArticleList(params) {
        return http.get(`${api}/app-jycy-zhuanlan/getActivityArticleList`, params)
    },
    //kol发布文章
    kolsaveArticle(params) {
        return http.post(`${api}/app-jycy-zhuanlan/saveArticle`, params)
    },
    //kol季度排行榜
    kolgetKOLArticleSort(params) {
        return http.get(`${api}/app-jycy-zhuanlan/getKOLArticleSort`, params)
    },
    //判断kol作者
    ifNotArticleApi(params) {
        return http.get(`${api}/app-jycy-zhuanlan/ifNotArticle`, params)
    },
    //个人中心查看文章列表
    getArticleList(params) {
        return http.get(`${api}/app-jycy-zhuanlan/getArticleList`, params)
    },
    //删除个人中心文章
    koldelArticle(params) {
        return http.get(`${api}/app-jycy-zhuanlan/delArticle`, params)
    },
    //回显个人中心文章
    kolgetArticleDetail(params) {
        return http.get(`${api}/app-jycy-zhuanlan/getArticleDetail`, params)
    },
    //专题-兰州市残疾人培训项目
    getLzTrNoticeList(params) {
        return http.get(`${api}/app-jycy-consultation/getLzTrNoticeList`, params)
    },
}
export default activity
