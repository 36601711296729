import http from '../plugins/utils/http'

let api = '/api'
const setting = {
    getTel(params) {
        return http.get(`${api}/app-jycy-member/getMobileOrTel/1`, params)
    },
    //根据手机号查询绑定的企业
    getIphoneCompany(params) {
        return http.get(`${api}/app-jycy-company/queryCompanyNameByPhone`, params)
    },
    //绑定手机号
    getBindPhoneApi(params) {
        return http.post(`${api}/app-jycy-member/bindPhone`, params)
    },
    //解绑手机号
    getUnBindApi(params) {
        return http.post(`${api}/app-jycy-member/unBind`, params)
    },
    // 修改手机号
    modifyTel(params) {
        return http.post(`${api}/app-jycy-disabled/updatePhone`, params)
    },
    //获取邮箱
    getEmail(params) {
        return http.get(`${api}/app-jycy-member/getMobileOrTel/2`, params)
    },
    //修改邮箱
    modifyEmail(params) {
        return http.post(`${api}/app-jycy-disabled/updateEmail`, params)
    },
    // 发送邮件
    sendModifyEmailCode(params) {
        return http.get(`${api}/app-jycy-captcha/sendModifyEmailCode`, params)
    },
    // 修改密码
    updateUserPwd(params) {
        return http.post(`${api}/app-jycy-member/updateUserPwd`, params)
    },

    // 简历可见
    queryIsSee(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/queryIsSee`, params)
    },
    // 简历可见-修改
    updateIsSee(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/updateIsSee`, params)
    },

    // 查询屏蔽公司
    queryShield(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/queryShield`, params)
    },
    //添加屏蔽公司
    addShield(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/addShield`, params)
    },
    // 删除屏蔽的公司
    deleteShield(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/deleteShield`, params)
    },
    // 查询未屏蔽的公司
    queryUnShield(params) {
        return http.get(`${api}/app-jycy-company/queryUnShield`, params)
    },
    // 手机号状态查询
    queryMemberTypeByPhoneApi(params) {
        return http.get(`${api}/app-jycy-member/queryMemberTypeByPhone`, params)
    },
    //注销游客账号
    getLogoffPhoneApi(params) {
        return http.post(`${api}/app-jycy-member/logoffPhone`, params)
    },
}
export default setting