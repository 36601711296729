import http from '../plugins/utils/http'

let api = '/api'
const train = {
    //机构列表
    mechanismListApi(params) {
        return http.get(`${api}/app-jycy-train/getTrainOrgList`, params)
    },
    //培训列表
    trainListApi(params) {
        return http.get(`${api}/app-jycy-train/getFwjgTrainList`, params)
    },
    //培训详情
    getFwjgTrainDetailApi(params) {
        return http.get(`${api}/app-jycy-train/getFwjgTrainDetail`, params)
    },
    //培训收藏
    collectTarinApi(params) {
        return http.get(`${api}/app-jycy-train/collectTarin`, params)
    },
    //报名信息回显
    getMyDisAbledInfoApi(params) {
        return http.get(`${api}/app-jycy-disabled/disbled/getMyDisAbledInfo`, params)
    },
    //培训报名
    applyTrainApi(params) {
        return http.post(`${api}/app-jycy-train/applyTrain`, params)
    },
    //公开课点击量
    publicClickApi(params) {
        return http.get(`${api}/app-jycy-train/addCouseHits`, params)
    },
    //公开课列表
    publicListApi(params) {
        return http.get(`${api}/app-jycy-train/gettrainCoursewarePersonList`, params)
    },
    //公开课详情
    publicDetailApi(params) {
        return http.get(`${api}/app-jycy-train/gettrainCoursewarePersonDetail`, params)
    },
    //公开课收藏
    publicCollectApi(params) {
        return http.get(`${api}/app-jycy-train/collectTrainCoursewarePerson`, params)
    },
    // 公开课课件列表
    publicVideoApi(params) {
        return http.get(`${api}/app-jycy-train/getTrainCoursewareList`, params)
    },
    // 公开课观看记录
    publicHistoryApi(params) {
        return http.get(`${api}/app-jycy-train/addOrupdateUserLook`, params)
    },
    //公开课历史回放
    getUserLookDetailApi(params) {
        return http.get(`${api}/app-jycy-train/getUserLookDetail`, params)
    },
    //筛选项
    selectListApi(params) {
        return http.get(`${api}/app-jycy-sysdict/commonDictValue`, params)
    },
    //机构详情
    getTrainOrgDetailApi(params) {
        return http.get(`${api}/app-jycy-train/getTrainOrgDetail`, params)
    },
    //单个机构下的培训列表
    getFwjgTrainSearchListApi(params) {
        return http.get(`${api}/app-jycy-train/getFwjgTrainSearchList`, params)
    },
    //职位收藏
    queryCollectionsJob(params) {
        return http.get(`${api}/app-jycy-job/disableds/queryCollectionsJob`, params)
    },
    //职位优选
    queryAboutApplyJob(params) {
        return http.get(`${api}/app-jycy-job/disableds/queryAboutApplyJob`, params)
    },
    //职位优选——湖南
    queryAboutHopeJob(params) {
        return http.get(`${api}/app-jycy-job/disableds/queryAboutHopeJob`, params)
    },
    //机构推荐
    jobToDisabledApi(params) {
        return http.get(`${api}/app-jycy-disabled/disabled/jobToDisabled`, params)
    },
    //我报名的培训
    getMyapplyTrain(params) {
        return http.get(`${api}/app-jycy-train/getMyapplyTrain`, params)
    },
    //我学习的
    getUserlookList(params) {
        return http.get(`${api}/app-jycy-train/getUserlookList`, params)
    },
    //我收藏的培训
    getCollectFwjgTrain(params) {
        return http.get(`${api}/app-jycy-train/getCollectFwjgTrain`, params)
    },
    //我收藏的公开课
    getMycollectCourse(params) {
        return http.get(`${api}/app-jycy-train/getMycollectCourse`, params)
    },
    // 查询培训项目列表
    queryCourseList(params) {
        return http.post(`${api}/app-jycy-newtrain/queryCourseList`, {...params, addressProvinceId:'p24'})
    },
    queryCourseDetailByIdApi(params) {
        return http.get(`${api}/app-jycy-newtrain/queryCourseDetailById`, params)
    },
    disabledInformationApi(params) {
        return http.get(`${api}/app-jycy-newtrain/disabledInformation`, params)
    },
    subscribeApplyApi(params) {
        return http.get(`${api}/app-jycy-newtrain/subscribeApply`, params)
    },
    getSignUpApi(params) {
        return http.post(`${api}/app-jycy-newtrain/signUp`, params)
    },
    updateDisabledInfo(params){
        return http.post(`${api}/app-jycy-newtrain/student/updateDisabledInfo`, params);
    },
    disabledLiuYan(params){
        return http.post(`${api}/app-jycy-newtrain/disabledLiuYan`, params);
    }
}
export default train
