<template>
  <div class="qr flexs">
    <div
      id="qrcode"
      ref="qrcode"
      :style="{ width: width + 'px', height: height + 'px' }"
    ></div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "qr",
  props: ["url","width", "height"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    // 生成二维码
    // qrCode() {
    //   this.$nextTick(function () {
    //     document.getElementById("qrcode").innerHTML = "";
    //     let qrcode = new QRCode("qrcode", {
    //       width: this.width * 5,
    //       height: this.height * 5,
    //  text:`${publicPath}/public/download/index.html`,
    //       text: "/public/download/index.html", // 二维码内容 也可以放url
    //       colorDark: "#000",
    //       colorLight: "#fff",
    //       correctLevel: QRCode.CorrectLevel.H,
    //     });
    //     qrcode.clear(); // clear the code.
    //     qrcode.makeCode("/public/download/index.html"); // make another code.
    //   });
    // },
    qrCode() {
      this.$nextTick(function () {
        document.getElementById("qrcode").innerHTML = "";
        let qrcode = new QRCode("qrcode", {
          width: this.width * 5,
          height: this.height * 5,
          text: this.url, // 二维码内容 也可以放url
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        qrcode.clear(); // clear the code.
        qrcode.makeCode(this.url); // make another code.
      });
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.qrCode();
    });
  },
};
</script>

<style scoped lang="less">
.qr {
  width: 100% !important;
  #qrcode {
    //background-color: #ffffff;
    display: inline-block;
    /deep/canvas {
      width: 114px;
      height: 114px;
    }
    /deep/ img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>