const state = {
    userInfo: '',
    userType:'',
    hasLogin: false,
    loginInfo: ' ',
    tokenInfo: '',
    refresh_token: '',
    time: ''
};

const actions = {
    resetVuex({ commit }) {
        commit("SET_USER_INFO", '');
    }
};

const mutations = {
    SET_USER_INFO(state, info) {
        localStorage.setItem('userInfo', JSON.stringify(info));
        localStorage.setItem('userType', JSON.stringify(info));
        state.userInfo = info;
    },
    SET_TOKEN(state, token) {
        // localStorage.setExpire('access_token', token,(60*60*24)*1000);
        localStorage.setItem('access_token', token);
        state.tokenInfo = token
    },
    SET_REFRESH_TOKEN(state, token) {
        // localStorage.setExpire('refresh_token', token,(60*60*24)*1000);
        localStorage.setItem('refresh_token', token);
        state.refresh_token = token
    },
    SET_INVATIVE_TIME(state, time) {
        state.time = time
    },

};

export default {
    state,
    actions,
    mutations
}