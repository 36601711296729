import http from '../plugins/utils/http'
import recruit from './recruit'
import dictionary from './dictionary'
import asklib from './asklib'
import section from './section'
import news from './new'
import enterpriseEnter from './enterpriseEnter'
import setting from './setting'
import activity from './activity'
import login from './login'
import center from './center'
import home from './home'
import train from './train'
import work from './work'
import jobFair from './jobFair'
import optimize from './optimize'
import hunan from './hunan'
import skill from './hunan/skill'
import create from './hunan/create'
import lanzhou from './lanzhou/index'
const api = {
    //首页
    homeApi(params) {
        return http.get('/api/app-jycy-usernotice/getSiteData', params)
    },
    //首页换一换公司
    moreCompany(params) {
        return http.get('/api/app-jycy-company/getAcTives', params)
    },
    //通知消息
    messageApi(params) {
        return http.get('/api/app-jycy-usernotice/queryNoticeListInfo', params)
    },
    //消息中心
    messageCenterApi(params) {
        return http.post('/api/app-jycy-usernotice/queryNoticeByType', params)
    },
    //单条已读
    changeReadStatusById(params) {
        return http.get(`/api/app-jycy-usernotice/changeReadStatusById`, params)
    },
    //一键已读
    readsApi(noticeType, params) {
        return http.get(`/api/app-jycy-usernotice/changeReadStatus/${noticeType}`, params)
    },
    // 热门城市
    hotCityApi(params) {
        return http.get('/json/hotCity.json', params)
    },
    // 全国城市筛选
    cityAllApi(params) {
        return http.get('/json/city.json', params)
    },
    //广告
    adApi(params) {
        return http.get('/api/app-jycy-ad/getAdList', params)
    },
    // 职位类别
    jobTypeApi(params) {
        return http.get(`/json/josType.json`, params)
    },
    // 行业筛选
    industryApi(params) {
        return http.get(`/json/industry.json`, params)
    },
    //河南省筛选
    henanCity(params) {
        return http.get(`/json/henan.json`, params)
    },
    //湖南筛选
    hunanCity(params) {
        return http.get(`/json/hunan`, params)
    },
    //陕西筛选
    shanxiCity(params) {
        return http.get(`/json/shanxi.json`, params)
    },
    //兰州筛选
    lanzhouCity(params) {
        return http.get(`/json/lanzhou.json`, params)
    },
    //问卷调查
    etTrainQuestionnaire(params) {
        return http.get(`/api/app-jycy-train/getTrainQuestionnaire`, params)
    },
    //提交问卷调查
    saveTrainQuestionnaire(params) {
        return http.post(`/api/app-jycy-train/saveTrainQuestionnaire`, params)
    },
    //年审员报名数据回显
    getWangTiTrainApplyDetail(params) {
        return http.get(`/api/app-jycy-train/getWangTiTrainApplyDetail`, params)
    },
    //年审员培训报名列表
    getWangTiTrainApplyPage(params) {
        return http.get(`/api/app-jycy-train/getWangTiTrainApplyPage`, params)
    },
    //网梯培训报名——获取登录时的加密参数
    // getWangTiParameterKey(params) {
    //     return http.get(`/api/app-jycy-train/getWangTiParameterKey`, params)
    // },
    //网梯培训报名新接口——获取登录时的加密参数
    getWangTiParameterKey(params) {
        return http.get(`/api/app-jycy-train/getWangTiExam`, params)
    },
    //根据定位获取对应的value值
    getCodeByCityNameApi(params) {
        return http.get(`/api/app-jycy-sysdict/getCodeByCityName`, params)
    },
    //招聘会城市筛选
    jobFairCity(params) {
        return http.get('/api/app-jycy-sysdict/queryDictByDiCode', params)
    },
    // 残疾人身份证校验接口
    disableInfoCheck(params) {
        return http.get('/api/app-jycy-security/disableInfoCheck', params)
    },
    // 三年行动调查问卷
    questionnaireSurvey(params) {
        return http.post('/api/app-jycy-activity/questionnaireSurvey', params)
    },
    // 查询是否已填过问卷
    checkAgeQuestionnaire(params) {
        return http.get('/api/app-jycy-activity/checkAgeQuestionnaire', params)
    },
    ...recruit,
    ...dictionary,
    ...asklib,
    ...enterpriseEnter,
    ...section,
    ...news,
    ...setting,
    ...activity,
    ...home,
    ...center,
    ...work,
    ...train,
    ...jobFair,
    ...login,
    ...optimize,
    ...hunan,
    ...skill,
    ...create,
    ...lanzhou
}
export default api