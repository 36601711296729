/*
 * @Description: 
 * @Version: 
 * @Autor: houcf
 * @Date: 2023-04-28 09:39:53
 * @LastEditTime: 2023-04-28 10:26:12
 */
export const dateSubMonth = function (date) {
    var date1 = new Date(date);
    var date2 = new Date();
    if (date1.getTime() > date2.getTime() || !date) {
        return 0;
    }
    var monthCount = parseInt(date2.getFullYear() - date1.getFullYear()) * 12 - date1.getMonth() + date2.getMonth();
    var resM = monthCount % 12;
    var resY = parseInt(monthCount / 12);
    var resStr = "";
    if (resY != 0) {
        resStr += resY + "年"
    }
    if (resM != 0) {
        resStr += resM + "月"
    }
    if (resStr == "") {
        resStr = "0";
    }
    return resStr;
}
export const dateFormat = (time, format = 'YYYY-MM-DD hh:mm') => {
    if (!time) return ''
    var timeDate = new Date(time);
    var year = timeDate.getFullYear();
    var month = timeDate.getMonth() + 1;
    var date = timeDate.getDate();
    var hours = timeDate.getHours();
    var minutes = timeDate.getMinutes();
    var seconds = timeDate.getSeconds();
    var newTime = format.replace(/YYYY/g, year).replace(/MM/, zeroize(month)).replace(/DD/, zeroize(date)).replace(/HH|hh/, zeroize(hours)).replace(/MM|mm/, zeroize(minutes)).replace(/SS|ss/, zeroize(seconds));
    return newTime;
}

function zeroize(num) {
    return ('0' + num).slice(-2)
}
// 倒计时
export function countDown(startTime, endTime, type) {
    var nowtime = new Date(), //获取当前时间
        endtime = new Date(endTime), //定义结束时间
        startTime = new Date(startTime);
    if (endtime < nowtime) { // 活动结束
        return {
            state: 2,
            time: null
        };
    }
    if (startTime > nowtime) { // 活动未开始
        endtime = new Date(startTime);
    }
    var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24), //计算小时数
        leftm = Math.floor(lefttime / (1000 * 60) % 60), //计算分钟数
        lefts = Math.floor(lefttime / 1000 % 60); //计算秒数
    var result = {};
    if (startTime > nowtime) {
        result = {
            state: 3, // 1进行中 2结束 3 未开始
            time: (lefth > 0 ? leftd + 1 : lefth) + "天"
        }
    } else {
        result = {
            state: 1, // 1进行中 2结束 3 未开始
            time: type === 'day' ? leftd > 0 ? leftd + "天" : '' : (leftd > 0 ? leftd + "天" : '') + lefth + "时" + leftm + "分"

        }
    }
    return result; //返回倒计时的字符串
}