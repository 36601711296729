export default {
    path: '/enterprise',
    component: () =>
        import ('../../views/Enterprise'),
    redirect: '/enterprise/centre/index',
    meta: {
        title: '企业个人中心',
        requireAuth: true,
        userType: 2
    },

    children: [
        {
        path: '/enterprise/centre/',
        name: "centre",
        component: () =>
            import ('../../views/enterprise/Centre'),
        children: [{
            path: '/enterprise/centre/index',
            name: "aaa",
            meta: {
                title: '企业个人中心-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/home/Index'),
        },
            {
                path: 'infomanage',
                name: "infomanage",
                meta: {
                    title: '信息管理-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/home/InfoManage'),
            },
            {
                path: 'jobfair',
                name: "jobfair",
                meta: {
                    title: '报名的招聘会-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/home/JobFair'),
            },
            {
                path: 'myask',
                name: "myask",
                meta: {
                    title: '我的问答-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/home/MyAsk'),
            },
            {
                path: 'mysection',
                name: "mysection",
                meta: {
                    title: '我的专栏-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/home/MySection'),
            },
            {
                path: 'mycreate',
                name: "mycreate",
                meta: {
                    title: '我的创业-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/home/MyCreate'),
            },
            {
                path: 'mycontest',
                name: "mycontest",
                meta: {
                    title: '我的竞赛-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/home/MyContest'),
            },
            {
                path: 'myneeds',
                name: "myneeds",
                meta: {
                    title: '我的需求-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/center/myNeeds'),
            },
            {
                path: 'phone',
                name: "phone",
                meta: {
                    title: '修改手机号-兰州市残疾人就业创业网络服务平台 ',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/setting/setting'),
            },
            {
                path: 'email',
                name: "email",
                meta: {
                    title: '修改邮箱-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/setting/settingEmail'),
            },
            {
                path: 'password',
                name: "password",
                meta: {
                    title: '修改密码-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/setting/settingPass'),
            },

            {
                path: 'myquit',
                name: "myquit",
                meta: {
                    title: '退出-兰州市残疾人就业创业网络服务平台',
                    requireAuth: true,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/home/MyQuit'),
            },
            {
                path: 'lanzhou/deposit',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/index.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'lanzhou/deposit/edit',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/edit.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'lanzhou/deposit/nextEdit',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/nextEdit.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'lanzhou/deposit/nextEdit1',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/nextEdit1.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'lanzhou/deposit/nextEdit2',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/nextEdit2.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'lanzhou/deposit/nextEdit3',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/nextEdit3.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'lanzhou/deposit/nextEdit4',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/nextEdit4.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'lanzhou/deposit/detail',
                component: () =>
                    import ('../../views/lanzhou-cdpe/enterprise/deposit/detail.vue'),
                meta: {
                    title: '残保金审核——兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 1
                },
            },
            {
                path: 'setting/phone',
                name: "phone",
                meta: {
                    title: '修改绑定手机号-兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/setting/phone.vue'),
            },
            {
                path: 'setting/unbind',
                name: "unbind",
                meta: {
                    title: '解除绑定-兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/setting/unbind.vue'),
            },
            {
                path: 'setting/tips',
                name: "tips",
                meta: {
                    title: '兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 2
                },
                component: () =>
                    import ('../../views/enterprise/setting/tips.vue'),
            },
        ]

    },
        {
            path: 'publish',
            name: "publish",
            meta: {
                title: '发布职位-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/recruiting/publish')
        },
        {
            path: 'wiki_list',
            name: "wiki_list",
            meta: {
                title: '残疾人百科列表页-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/news/wiki_list')
        },
        {
            path: 'post',
            name: "post",
            meta: {
                title: '职位管理-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/post/Post.vue'),
        },
        {
            path: 'postDetails',
            name: "postDetails",
            meta: {
                title: '职位管理-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/job/jobDetail.vue'),
        },
        {
            path: 'recruiting',
            name: "recruiting",
            meta: {
                title: '找人才-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/recruiting/Recruiting'),
            // children: [{
            //     path: "4"

            // }]

        },
        {
            path: 'netJob',
            name: "netJob",
            meta: {
                title: '网络招聘会-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/netJob/NetJob.vue'),

        },
        {
            path: 'joinJob',
            name: "joinJob",
            meta: {
                title: '参会职位-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/netJob/JoinJob')

        },
        {
            path: 'netDetail',
            name: "netDetail",
            meta: {
                title: '参会机构详情页-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/netJob/NetDetail.vue')

        },
        {
            path: 'resumes',
            name: "resumes",
            meta: {
                title: '简历管理-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/resumes/Resumes')
        },
        {
            path: 'ask',
            name: "ask",
            meta: {
                title: '问答-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/asklib/index')
        },
        {
            path: 'section',
            name: 'section',
            meta: {
                title: '专栏-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/section/index')
        },
        {
            path: 'wiki',
            name: 'wiki',
            meta: {
                title: '残疾人百科-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 2
            },
            component: () =>
                import ('../../views/enterprise/wiki/wiki')
        },
        {
            path: 'activity_inline',
            name: "activity_inline",
            meta: {
                title: '线上-兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 2
            },
            component: () =>
                import ('../../views/activity/Inline.vue'),
        }, {
            path: 'activity_outline',
            name: "activity_outline",
            meta: {
                title: '线下-兰州市残疾人就业创业网络服务平台',
                requireAuth: false,
                userType: 2
            },
            component: () =>
                import ('../../views/activity/Outline.vue'),
            children: [{
                path: 'activity_details',
                name: "activity_details",
                meta: {
                    title: '活动详情页-兰州市残疾人就业创业网络服务平台',
                    requireAuth: false,
                    userType: 2
                },
                component: () =>
                    import ('../../views/activity/activityDetail.vue'),
            },]

        },

    ]
}
