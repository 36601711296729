export default [
    {
        path: '/activity/jobFair',
        component: () =>
            import ('../../views/activity/jobFair-country/index'),
        meta: {
            title: '专题首页-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/activity/employment-guidance',
        component: () =>
            import ('../../views/activity/jobFair-country/employment-guidance'),
        meta: {
            title: '职位推荐-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/activity/policy',
        component: () =>
            import ('../../views/activity/jobFair-country/policy'),
        meta: {
            title: '就业政策-中国残联就业创业服务平台',
            requireAuth: true,
            userType: 2
        },
    },
    {
        path: '/activity/trainProject',
        component: () =>
            import ('../../views/activity/trainProject/index'),
        meta: {
            title: '专题页-兰州市残疾人培训项目',
            requireAuth: true,
            userType: 2
        },
    },
]
