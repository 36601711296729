export default {
    path: '/center',
    component: () =>
        import ('../../views/center'),
    redirect: '/center/index',
    meta: {
        title: '残疾人个人中心-兰州市残疾人就业创业网络服务平台',
        requireAuth: true,
        userType: 1,
    },
    children: [{
            path: '/center/index',
            component: () =>
                import ('../../views/center/index'),
            meta: {
                title: '残疾人个人中心-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/indexs',
            component: () =>
                import ('../../views/center/indexs'),
            meta: {
                title: '残疾人个人中心2-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/resume',
            component: () =>
                import ('../../views/center/resume'),
            meta: {
                title: '个人简历-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/work',
            component: () =>
                import ('../../views/center/work'),
            meta: {
                title: '我的投递-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
		{
		    path: '/center/annual',
		    component: () =>
		        import ('../../views/center/annual'),
		    meta: {
		        title: '年审员培训-兰州市残疾人就业创业网络服务平台',
		        requireAuth: true,
		        userType: 1
		    }
		},
        {
            path: '/center/training',
            component: () =>
                import ('../../views/center/training'),
            meta: {
                title: '我的培训-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/online',
            component: () =>
                import ('../../views/center/online.vue'),
            meta: {
                title: '视频面试-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/myask',
            component: () =>
                import ('../../views/enterprise/home/MyAsk.vue'),
            meta: {
                title: '我的问答-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/column',
            component: () =>
                import ('../../views/enterprise/home/MySection.vue'),
            meta: {
                title: '我的专栏-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/collect',
            component: () =>
                import ('../../views/center/collect'),
            meta: {
                title: '我的收藏-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/myNeeds',
            component: () =>
                import ('../../views/center/myNeeds.vue'),
            meta: {
                title: '我的需求-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/myCreate',
            component: () =>
                import ('../../views/center/myCreate.vue'),
            meta: {
                title: '我的创业-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/myAppoint',
            component: () =>
                import ('../../views/center/myAppoint.vue'),
            meta: {
                title: '我的创业-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/myContest',
            component: () =>
                import ('../../views/center/myContest.vue'),
            meta: {
                title: '我的竞赛-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/setting',
            component: () =>
                import ('../../views/setting/setting'),
            meta: {
                title: '修改手机号-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/settingUser',
            component: () =>
                import ('../../views/setting/settingUser'),
            meta: {
                title: '账号绑定-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/settingEmail',
            component: () =>
                import ('../../views/setting/settingEmail'),
            meta: {
                title: '修改邮箱-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/settingPass',
            component: () =>
                import ('../../views/setting/settingPass'),
            meta: {
                title: '修改密码-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/settingPrivacy',
            component: () =>
                import ('../../views/setting/settingPrivacy'),
            meta: {
                title: '隐私设置-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        // henan---开始
        {
            path: 'flexbile',
            component: () =>
                import ('../../views/center/henan/flexbile.vue'),
            meta: {
                title: '灵活就业-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: 'guideCreate',
            component: () =>
                import ('../../views/center/henan/guideCreate.vue'),
            meta: {
                title: '就创业指导-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: 'putQuestion',
            component: () =>
                import ('../../views/center/henan/putQuestion.vue'),
            meta: {
                title: '就创业指导—提问-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: 'leaveMessage',
            component: () =>
                import ('../../views/center/henan/leaveMessage.vue'),
            meta: {
                title: '就创业指导-留言-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: 'videoInterview',
            component: () =>
                import ('../../views/center/henan/videoInterview.vue'),
            meta: {
                title: '视频面试-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: 'orderMange',
            component: () =>
                import ('../../views/center/henan/orderMange.vue'),
            meta: {
                title: '订单管理-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: 'worMange',
            component: () =>
                import ('../../views/lanzhou-cdpe/center/worMange'),
            meta: {
                title: '作品管理-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/center/product',
            name: 'publish',
            component: () =>
                import ('../../views/lanzhou-cdpe/center/product'),
            meta: {
                title: '发布产品',
                requireAuth: true,
                memberType: 1
            }
        },
        {
            path: 'mentalAppointment',
            component: () =>
                import ('../../views/center/henan/mentalAppointment.vue'),
            meta: {
                title: '心理预约-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        // henan---结束
        // shanxi---开始
        {
            path: '/center/myGuide',
            component: () =>
                import ('../../views/center/myGuide.vue'),
            meta: {
                title: '就业指导-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        // lanzhou---开始
        {
            path: '/lanzhou/center/blind',
            component: () =>
                import ('../../views/lanzhou-cdpe/center/blind/index.vue'),
            meta: {
                title: '盲医考继续教育-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
        {
            path: '/lanzhou/center/blind/blindEdit',
            component: () =>
                import ('../../views/lanzhou-cdpe/center/blind/blindEdit.vue'),
            meta: {
                title: '盲医考继续教育-兰州市残疾人就业创业网络服务平台',
                requireAuth: true,
                userType: 1
            }
        },
    ]
}
